@function vw($px) {
  @return ($px / 720) * 100vw;
}

body {
  background: #fff;
}

html, body {
  height: 100%;
}