/* http://meyerweb.com/eric/tools/css/reset/
  v2.0-modified | 20110126
  License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;

  /* font: inherit; */
  vertical-align: baseline;
  border: 0;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  color: #333;
  font-size: 0.14rem;
  font-family: -apple-system, Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.15;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

/* stylelint-disable */
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
/* stylelint-enable */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  /* stylelint-disable */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* stylelint-enable */
}

input[type='search'] {
  /* stylelint-disable */
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
  /* stylelint-enable */
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  /* stylelint-disable */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
  /* stylelint-enable */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  *margin-left: -7px; /* 3 */
  padding: 0;
  white-space: normal; /* 2 */
  border: 0; /* 1 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  margin: 0; /* 2 */
  font-size: 100%; /* 1 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  *overflow: visible; /* 4 */
  /* stylelint-disable */
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  /* stylelint-enable */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box; /* 1 */
  *width: 13px; /* 3 */
  *height: 13px; /* 3 */
  padding: 0; /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  /* stylelint-disable */
  -webkit-appearance: none;
  /* stylelint-enable */
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

/* stylelint-disable */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
/* stylelint-enable */

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

img {
  vertical-align: middle;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.chromeframe {
  margin: 0.2em 0;
  padding: 0.2em 0;
  color: #000;
  background: #ccc;
}

@supports not (color: var(--adm-color-text)) {
  .adm-button {
    color: #333333;
    background-color: #ffffff;
    font-size: 17px;
    border-width: 1px;
    border-style: solid;
    border-color: #eeeeee;
    border-radius: 4px;
  }
  .adm-button::before {
    transform: translate(-1px, -1px);
    border: 1px solid #000;
    border-radius: 4px;
  }
  .adm-button-default.adm-button-fill-outline {
    background-color: transparent;
    border-color: #333333;
  }
  .adm-button-default.adm-button-fill-none {
    background-color: transparent;
    border-width: 0;
  }
  .adm-button:not(.adm-button-default) {
    color: #ffffff;
  }
  .adm-button:not(.adm-button-default).adm-button-fill-outline {
    background-color: transparent;
  }
  .adm-button:not(.adm-button-default).adm-button-fill-none {
    background-color: transparent;
    border-width: 0;
  }
  .adm-button-primary {
    background-color: #1677ff;
    border-color: #1677ff;
  }
  .adm-button-primary.adm-button-fill-outline,
  .adm-button-primary.adm-button-fill-none {
    color: #1677ff;
  }
  .adm-button-success {
    background-color: #00b578;
    border-color: #00b578;
  }
  .adm-button-success.adm-button-fill-outline,
  .adm-button-success.adm-button-fill-none {
    color: #00b578;
  }
  .adm-button-danger {
    background-color: #00b578;
    border-color: #00b578;
  }
  .adm-button-danger.adm-button-fill-outline,
  .adm-button-danger.adm-button-fill-none {
    color: #00b578;
  }
  .adm-button-warning {
    background-color: #ff8f1f;
    border-color: #ff8f1f;
  }
  .adm-button-warning.adm-button-fill-outline,
  .adm-button-warning.adm-button-fill-none {
    color: #ff8f1f;
  }
  .adm-button.adm-button-mini {
    font-size: 13px;
  }
  .adm-button.adm-button-small {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 15px;
  }
  .adm-button.adm-button-large {
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 18px;
  }
  .adm-button.adm-button-shape-rounded {
    border-radius: 1000px;
  }
  .adm-button.adm-button-shape-rounded::before {
    border-radius: 1000px;
  }
  .adm-button.adm-button-shape-rectangular {
    border-radius: 0;
  }
  .adm-cascader .adm-cascader-view-content {
    height: 310px;
  }
  .adm-center-popup {
    z-index: 1000;
  }
  .adm-center-popup-wrap {
    min-width: 280px;
    max-width: 75vw;
  }
  .adm-center-popup-body {
    background-color: #ffffff;
    border-radius: 8px;
  }
  .adm-error-block-image {
    height: 100px;
    width: auto;
  }
  .adm-error-block-description {
    font-size: var(--adm-font-size-4);
  }
  .adm-error-block-description-title {
    font-size: var(--adm-font-size-7);
  }
  .adm-error-block-full-page {
    padding-top: calc(50vh - 200px);
  }
  .adm-error-block-full-page .adm-error-block-image {
    height: 200px;
    width: auto;
  }
  .adm-error-block-full-page .adm-error-block-description {
    font-size: var(--adm-font-size-main);
  }
  .adm-error-block-full-page .adm-error-block-description-title {
    color: #333333;
  }
  .adm-image {
    width: auto;
    height: auto;
  }
  .adm-image-tip {
    background-color: #f5f5f5;
  }
  .adm-image-tip > svg {
    color: #999999;
  }
  .adm-mask {
    z-index: 1000;
  }
  .adm-modal-body {
    font-size: 14px;
  }
  .adm-modal-title {
    font-size: 18px;
  }
  .adm-modal-content {
    font-size: 15px;
    color: #333333;
  }
  .adm-modal-close {
    color: #999999;
    font-size: 18px;
  }
  .adm-modal-footer.adm-space.adm-space-vertical > .adm-space-item.adm-space-item {
    margin-bottom: 20px;
  }
  .adm-modal-footer.adm-space.adm-space-vertical > .adm-space-item.adm-space-item:last-child {
    margin-bottom: 0;
  }
  .adm-modal-footer .adm-modal-button {
    font-size: 18px;
  }
  .adm-page-indicator-dot {
    width: 3px;
    height: 3px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.2);
  }
  .adm-page-indicator-dot-active {
    border-radius: 1px;
    background: #1677ff;
  }
  .adm-page-indicator-color-white .adm-page-indicator-dot-active.adm-page-indicator-dot-active {
    background: #ffffff;
  }
  .adm-page-indicator-horizontal .adm-page-indicator-dot {
    margin-right: 3px;
  }
  .adm-page-indicator-horizontal .adm-page-indicator-dot-active {
    width: 13px;
  }
  .adm-page-indicator-vertical .adm-page-indicator-dot {
    margin-bottom: 3px;
  }
  .adm-page-indicator-vertical .adm-page-indicator-dot-active {
    height: 13px;
  }
  .adm-popover-menu.adm-popover .adm-popover-inner-content {
    padding: 0 !important;
  }
  .adm-popover-menu-item-text {
    border-top: solid 1px #eeeeee;
  }
  .adm-popover-menu-item:active:not(.adm-popover-menu-item-disabled) {
    background-color: #eeeeee;
  }
  .adm-popover-menu-item:active:not(.adm-popover-menu-item-disabled)::after {
    border-bottom: solid 1px #eeeeee;
  }
  .adm-popover.adm-popover-dark.adm-popover-menu.adm-popover-menu .adm-popover-menu-item-text {
    border-color: #333333;
  }
  .adm-popover.adm-popover-dark.adm-popover-menu.adm-popover-menu .adm-popover-menu-item:active:not(.adm-popover-menu-item-disabled) {
    background-color: #333333;
  }
  .adm-popover.adm-popover-dark.adm-popover-menu.adm-popover-menu .adm-popover-menu-item:active:not(.adm-popover-menu-item-disabled)::after {
    border-color: #333333;
  }
  .adm-popover.adm-popover-dark.adm-popover-menu.adm-popover-menu .adm-popover-inner.adm-popover-inner.adm-popover-inner {
    background-color: rgba(0, 0, 0, 0.9);
  }
  .adm-popover {
    z-index: 1030;
    color: #333333;
  }
  .adm-popover.adm-popover-dark {
    color: #ffffff;
  }
  .adm-popover.adm-popover-dark .adm-popover-inner {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .adm-popover-inner {
    background-color: #ffffff;
    font-size: 15px;
  }
  .adm-popover-inner-content {
    padding: 8px 12px;
  }
  .adm-popover-arrow {
    display: none !important;
  }
  .adm-scroll-mask-left {
    background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
  }
  .adm-scroll-mask-right {
    background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
  }
  .adm-space-vertical > .adm-space-item {
    margin-bottom: 8px;
  }
  .adm-space-horizontal > .adm-space-item {
    margin-right: 8px;
  }
  .adm-space-horizontal.adm-space-wrap {
    flex-wrap: wrap;
    margin-bottom: -8px;
  }
  .adm-space-horizontal.adm-space-wrap > .adm-space-item {
    padding-bottom: 8px;
  }
  .adm-spin-loading {
    width: 32px;
    height: 32px;
  }
  .adm-spin-loading-svg {
    width: 100%;
    height: 100%;
    animation: adm-spin-loading-rotate 0.8s infinite linear;
  }
  .adm-spin-loading-svg > .adm-spin-loading-fill {
    stroke: #999999;
  }
  .adm-swiper {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0;
  }
  .adm-swiper-track {
    padding: 0;
  }
  .adm-swiper-horizontal .adm-swiper-track {
    transform: translateX(0%);
  }
  .adm-swiper-horizontal .adm-swiper-track-inner {
    width: 100%;
  }
  .adm-swiper-vertical .adm-swiper-track {
    transform: translateY(0%);
  }
  .adm-swiper-vertical .adm-swiper-track-inner {
    height: 100%;
  }
  .adm-tabs-header {
    border-bottom: solid 1px #eeeeee;
  }
  .adm-tabs-tab {
    font-size: 17px;
  }
  .adm-tabs-tab-active {
    color: #1677ff;
  }
  .adm-tabs-tab-line {
    height: 2px;
    background: #1677ff;
    border-radius: 2px;
  }
  .adm-tabs-content {
    padding: 12px;
  }
  .adm-tabs-header-mask-left {
    background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
  }
  .adm-tabs-header-mask-right {
    background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
  }
  .adm-toast-mask .adm-toast-main {
    font-size: 15px;
  }
  .adm-toast-loading.adm-toast-loading {
    width: 48px;
    height: 48px;
    margin: 0 auto 8px;
  }
  .adm-toast-loading.adm-toast-loading .adm-spin-loading-fill {
    stroke: #ffffff;
  }
}

body{background:#fff}html,body{height:100%}
